import { Link } from "@remix-run/react";

import { cn } from "~/lib/utils";
import { BASE_PATH, DASHBOARD_PATH } from "~/utils/paths";

type Props = { className?: string; variant: "dashboard" | "marketing" };

export function Logo({ className, variant }: Props) {
  return (
    <Link
      className={cn("shrink-0 font-serif text-lg font-semibold", className)}
      to={variant === "dashboard" ? DASHBOARD_PATH : BASE_PATH}
    >
      Conveo
    </Link>
  );
}
